import React from "react";
import Flex from "app/components/Flex";
import VideoCameraBackground from "app/assets/svg/video-camera-background.svg";
import Text from "app/components/Text";

export const VideoEncodingState = ({ key }: { key: string }) => {
  return (
    <Flex justifyContent="center" key={key} cursor="default">
      <Flex flexDirection="column">
        <Flex position="relative" flexDirection="column">
          <Flex as={VideoCameraBackground} width="100%" />
          <Flex
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            padding="8px"
          >
            <Text color="white" fontSize="14px" textAlign="center">
              Your video is still processing. Check back in a few minutes!
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
